import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  ButtonGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
  Badge,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import moment from "moment";

function ComisionesCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_DEVOLUCIONES = process.env.REACT_APP_URL_DEVOLUCIONES;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_COMISIONES = process.env.REACT_APP_URL_COMISIONES;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [fecha, setFecha] = useState(hoy);

  const [inputFieldsVentas, setInputFieldsVentas] = useState([]);
  const [validaBoton, setValidaBoton] = useState(true);
  const [total, setTotal] = useState(0);
  const [totalVenta, setTotalVenta] = useState(0);
  const [porcentaje, setPorcentaje] = useState(0);

  const [inputFieldsDevs, setInputFieldsDevs] = useState([]);
  const [allInputFieldsDevs, setAllInputFieldsDevs] = useState([]); // Store all devoluciones
  const [totalDev, setTotalDev] = useState(0);
  
  const [inputFieldsBons, setInputFieldsBons] = useState([]);
  const [allInputFieldsBons, setAllInputFieldsBons] = useState([]); // Store all bonificaciones
  const [totalBon, setTotalBon] = useState(0);

  const [totalResumen, setTotalResumen] = useState(0);

  const [cambioVista, setCambioVista] = useState(0);

  const [selectAllVentas, setSelectAllVentas] = useState(false);
  const [selectAllDevs, setSelectAllDevs] = useState(false);
  const [selectAllBons, setSelectAllBons] = useState(false);

  useEffect(()=>{
    axios
    .get(`${URL_CLIENTES}General`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    },[]);
  
    useMemo(() => {
  
      if (selectedColaborador) {
  
        if(selectedCliente){
  
        // Surtidos
        axios
          .get(`${URL_SURTIDOS}ColaboradorCliente/${selectedColaborador}/${selectedCliente}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allSurtidos = response.data;
            let arrayTabla = allSurtidos
              .sort((a, b) => (a.idSurtido < b.idSurtido ? 1 : -1))
              .map((a) => {
                return {
                  _id: a._id,
                  numero: a.idSurtido,
                  fecha: a.fecha,
                  clientes: a.clientes[0].nombre_comercial,
                  idClientes: a.clientes[0]._id,
                  importe: a.subTotal,
                  comision: 0,
                  select: false,
                };
              })
              .filter(function (el) {
                return el != null;
              });
  
            let data = Object.values(arrayTabla);
            setInputFieldsVentas(data);
  
            let activos = data.filter((e) => e.select == true);
            let TVenta = activos.map((c) => parseFloat(c.importe));
            let TV = TVenta.reduce((t, total, index) => t + total, 0);
            setTotalVenta(TV);
          })
          .catch((err) => {
            console.log(err);
          });
  
        // Devoluciones
        axios
          .get(`${URL_DEVOLUCIONES}ColaboradorCliente/${selectedColaborador}/${selectedCliente}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allDev = response.data;
            let arrayTabla = allDev
              .sort((a, b) => (a.idDevolucion < b.idDevolucion ? 1 : -1))
              .map((a) => {
                if(a.surtidos[0].saldo == 0){
                return {
                  _id: a._id,
                  numero: a.idDevolucion,
                  fecha: a.fecha,
                  clientes: a.clientes[0].nombre_comercial,
                  idClientes: a.clientes[0]._id,
                  importe: a.subTotal,
                  comision: 0,
                  select: false,
                  idSurtido: a.surtidos[0]._id
                };}
              })
              .filter(function (el) {
                return el != null;
              });
  
            let data = Object.values(arrayTabla);
            setInputFieldsDevs(data);
            setAllInputFieldsDevs(data); // Store all devoluciones
  
            let activos = data.filter((e) => e.select == true);
            let TDev = activos.map((c) => parseFloat(c.importe));
            let TD = TDev.reduce((t, total, index) => t + total, 0);
            setTotalDev(TD);
          })
          .catch((err) => {
            console.log(err);
          });
  
        // Bonificaciones
        axios
          .get(`${URL_ABONOS}ColaboradorCliente/${selectedColaborador}/${selectedCliente}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allBon = response.data;
            let arrayTabla = allBon
              .sort((a, b) => (a.idAbono < b.idAbono ? 1 : -1))
              .map((a) => {
                if(a.surtidos[0].saldo == 0){
                return {
                  _id: a._id,
                  numero: a.idAbono,
                  fecha: a.fecha,
                  clientes: a.clientes[0].nombre_comercial,
                  idClientes: a.clientes[0]._id,
                  importe: a.importe,
                  comision: 0,
                  select: false,
                  idSurtido: a.surtidos[0]._id
                };}
              })
              .filter(function (el) {
                return el != null;
              });
  
            let data = Object.values(arrayTabla);
            setInputFieldsBons(data);
            setAllInputFieldsBons(data); // Store all bonificaciones
  
            let activos = data.filter((e) => e.select == true);
            let TBon = activos.map((c) => parseFloat(c.importe));
            let TB = TBon.reduce((t, total, index) => t + total, 0);
            setTotalBon(TB);
          })
          .catch((err) => {
            console.log(err);
          });
  
        }else{
        // Surtidos
        axios
          .get(`${URL_SURTIDOS}Colaborador/${selectedColaborador}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allSurtidos = response.data;
            let arrayTabla = allSurtidos
              .sort((a, b) => (a.idSurtido < b.idSurtido ? 1 : -1))
              .map((a) => {
                return {
                  _id: a._id,
                  numero: a.idSurtido,
                  fecha: a.fecha,
                  clientes: a.clientes[0].nombre_comercial,
                  idClientes: a.clientes[0]._id,
                  importe: a.subTotal,
                  comision: 0,
                  select: false,
                };
              })
              .filter(function (el) {
                return el != null;
              });
  
            let data = Object.values(arrayTabla);
            setInputFieldsVentas(data);
  
            let activos = data.filter((e) => e.select == true);
            let TVenta = activos.map((c) => parseFloat(c.importe));
            let TV = TVenta.reduce((t, total, index) => t + total, 0);
            setTotalVenta(TV);
          })
          .catch((err) => {
            console.log(err);
          });
  
        // Devoluciones
        axios
          .get(`${URL_DEVOLUCIONES}Colaborador/${selectedColaborador}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allDev = response.data;
            let arrayTabla = allDev
              .sort((a, b) => (a.idDevolucion < b.idDevolucion ? 1 : -1))
              .map((a) => {
                if(a.surtidos[0].saldo == 0){
                return {
                  _id: a._id,
                  numero: a.idDevolucion,
                  fecha: a.fecha,
                  clientes: a.clientes[0].nombre_comercial,
                  idClientes: a.clientes[0]._id,
                  importe: a.subTotal,
                  comision: 0,
                  select: false,
                  idSurtido: a.surtidos[0]._id
                };
                }
              })
              .filter(function (el) {
                return el != null;
              });
  
            let data = Object.values(arrayTabla);
            setInputFieldsDevs(data);
            setAllInputFieldsDevs(data); // Store all devoluciones
  
            let activos = data.filter((e) => e.select == true);
            let TDev = activos.map((c) => parseFloat(c.importe));
            let TD = TDev.reduce((t, total, index) => t + total, 0);
            setTotalDev(TD);
          })
          .catch((err) => {
            console.log(err);
          });
  
        // Bonificaciones
        axios
          .get(`${URL_ABONOS}Colaborador/${selectedColaborador}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allBon = response.data;
            let arrayTabla = allBon
              .sort((a, b) => (a.idAbono < b.idAbono ? 1 : -1))
              .map((a) => {
                if(a.surtidos[0].saldo == 0){
                return {
                  _id: a._id,
                  numero: a.idAbono,
                  fecha: a.fecha,
                  clientes: a.clientes[0].nombre_comercial,
                  idClientes: a.clientes[0]._id,
                  importe: a.importe,
                  comision: 0,
                  select: false,
                  idSurtido: a.surtidos[0]._id
                };
                }
              })
              .filter(function (el) {
                return el != null;
              });
  
            let data = Object.values(arrayTabla);
            setInputFieldsBons(data);
            setAllInputFieldsBons(data); // Store all bonificaciones
  
            let activos = data.filter((e) => e.select == true);
            let TBon = activos.map((c) => parseFloat(c.importe));
            let TB = TBon.reduce((t, total, index) => t + total, 0);
            setTotalBon(TB);
          })
          .catch((err) => {
            console.log(err);
          })}
      }
    }, [selectedColaborador, selectedCliente]);
  
    function savePago() {
      if (porcentaje != 0) {
        Swal.fire({
          title: "Estas seguro?",
          text: "Se registrará el pago",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Enviar!",
        }).then((result) => {
          if (result.isConfirmed) {
            toggleProgreso();
            setValidaBoton(false);
            let totalMails =
              inputFieldsVentas.length +
              inputFieldsDevs.length +
              inputFieldsBons.length;
  
            axios
              .post(
                `${URL_COMISIONES}`,
                {
                  fecha,
                  importe: total,
                  colaboradores: selectedColaborador,
                  porcentaje,
                  total: totalResumen
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
              .then((data) => {
                // Ventas
                inputFieldsVentas.map((a) => {
                  if (a.select == true) {
                    axios
                      .patch(
                        `${URL_SURTIDOS}/${a._id}`,
                        {
                          comisionPagada: "Si",
                          comisiones: data.data._id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        totalMails = totalMails - 1;
                        if (totalMails == 0) {
                          axios.post(
                            URL_LOGS,
                            {
                              tipo: "Pagar Comisiones",
                              detalle: `Fecha: ${fecha} Vendedor: ${selectedColaborador} `,
                              user: user.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          );
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        }
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                        });
                        console.log(error);
                      });
                  } else {
                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      axios.post(
                        URL_LOGS,
                        {
                          tipo: "Pagar Comisiones",
                          detalle: `Fecha: ${fecha} Vendedor: ${selectedColaborador} `,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      );
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    }
                  }
                });
  
                // Devoluciones
                inputFieldsDevs.map((a) => {
                  if (a.select == true) {
                    axios
                      .patch(
                        `${URL_DEVOLUCIONES}/${a._id}`,
                        {
                          comisionPagada: "Si",
                          comisiones: data.data._id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        totalMails = totalMails - 1;
                        if (totalMails == 0) {
                          axios.post(
                            URL_LOGS,
                            {
                              tipo: "Pagar Comisiones",
                              detalle: `Fecha: ${fecha} Vendedor: ${selectedColaborador} `,
                              user: user.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          );
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        }
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                        });
                        console.log(error);
                      });
                  } else {
                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      axios.post(
                        URL_LOGS,
                        {
                          tipo: "Pagar Comisiones",
                          detalle: `Fecha: ${fecha} Vendedor: ${selectedColaborador} `,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      );
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    }
                  }
                });
  
                // Bonificaciones
                inputFieldsBons.map((a) => {
                  if (a.select == true) {
                    axios
                      .patch(
                        `${URL_ABONOS}Comision/${a._id}`,
                        {
                          comisionPagada: "Si",
                          comisiones: data.data._id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        totalMails = totalMails - 1;
                        if (totalMails == 0) {
                          axios.post(
                            URL_LOGS,
                            {
                              tipo: "Pagar Comisiones",
                              detalle: `Fecha: ${fecha} Vendedor: ${selectedColaborador} `,
                              user: user.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          );
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        }
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                        });
                        console.log(error);
                      });
                  } else {
                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      axios.post(
                        URL_LOGS,
                        {
                          tipo: "Pagar Comisiones",
                          detalle: `Fecha: ${fecha} Vendedor: ${selectedColaborador} `,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      );
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    }
                  }
                });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                });
                console.log(error);
              });
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Debes capturar el Porcentaje",
        });
      }
    }
  

    function ActualizaTotalesSurtidos() {
      let activos = inputFieldsVentas.filter((e) => e.select == true);
  
      let TVenta = activos.map((c) => parseFloat(c.importe));
      let TV = TVenta.length > 0 ? TVenta.reduce((t, total) => t + total, 0) : 0;
      setTotalVenta(TV);
  
      let res = TV - totalDev - totalBon;
      setTotalResumen(res);
      
      // If we're changing the selected ventas in the first view, 
      // we should reset the devoluciones and bonificaciones selection
      if (cambioVista === 0) {
        setSelectAllDevs(false);
        setSelectAllBons(false);
      }
    }
  
    function ActualizaTotalesDevs() {
      let activos = inputFieldsDevs.filter((e) => e.select == true);
      let TDev = activos.map((c) => parseFloat(c.importe));
      let TD = TDev.length > 0 ? TDev.reduce((t, total) => t + total, 0) : 0;
      setTotalDev(TD);
  
      let res = totalVenta - TD - totalBon;
      setTotalResumen(res);
    }
  
    function ActualizaTotalesBons() {
      let activos = inputFieldsBons.filter((e) => e.select == true);
      let TBon = activos.map((c) => parseFloat(c.importe));
      let TB = TBon.length > 0 ? TBon.reduce((t, total) => t + total, 0) : 0;
      setTotalBon(TB);
  
      let res = totalVenta - totalDev - TB;
      setTotalResumen(res);
    }
  
    function calculaComision(e) {
      setPorcentaje(e);
  
      let pRes = (totalResumen * e) / 100;
      setTotal(pRes);
    }

    const handleSelectAllVentas = () => {
      setSelectAllVentas(!selectAllVentas);
      setInputFieldsVentas(
        inputFieldsVentas.map((data) => ({
          ...data,
          select: !selectAllVentas,
          comision: !selectAllVentas
            ? (parseFloat(data.importe) * porcentaje) / 100
            : 0,
        }))
      );
    };
  
    useEffect(() => {
      ActualizaTotalesSurtidos();
    }, [inputFieldsVentas]); // Llamada a la función dentro del useEffect
  
    const handleSelectAllDevs = () => {
      setSelectAllDevs(!selectAllDevs);
      setInputFieldsDevs(
        inputFieldsDevs.map((data) => ({
          ...data,
          select: !selectAllDevs,
          comision: !selectAllDevs
            ? (parseFloat(data.importe) * porcentaje) / 100
            : 0,
        }))
      );
    };
  
    useEffect(() => {
      ActualizaTotalesDevs();
    }, [inputFieldsDevs]); // Llamada a la función dentro del useEffect
  
  
    const handleSelectAllBons = () => {
      setSelectAllBons(!selectAllBons);
      setInputFieldsBons(
        inputFieldsBons.map((data) => ({
          ...data,
          select: !selectAllBons,
          comision: !selectAllBons
            ? (parseFloat(data.importe) * porcentaje) / 100
            : 0,
        }))
      );
    };
  
    useEffect(() => {
      ActualizaTotalesBons();
    }, [inputFieldsBons]); // Llamada a la función dentro del useEffect
    
    // New function to filter devoluciones and bonificaciones based on selected sales
    const filterRelatedTransactions = () => {
      // Get the IDs of selected sales
      const selectedSurtidosIds = inputFieldsVentas
        .filter(item => item.select)
        .map(item => item._id);
      
      // Filter devoluciones that are related to selected sales
      const filteredDevs = allInputFieldsDevs.filter(
        dev => selectedSurtidosIds.includes(dev.idSurtido)
      );
      
      // Filter bonificaciones that are related to selected sales
      const filteredBons = allInputFieldsBons.filter(
        bon => selectedSurtidosIds.includes(bon.idSurtido)
      );
      
      // Update the state with filtered data
      setInputFieldsDevs(filteredDevs);
      setInputFieldsBons(filteredBons);
      
      // Update totals
      let TDev = filteredDevs.filter(e => e.select).map(c => parseFloat(c.importe));
      let TD = TDev.length > 0 ? TDev.reduce((t, total) => t + total, 0) : 0;
      setTotalDev(TD);
      
      let TBon = filteredBons.filter(e => e.select).map(c => parseFloat(c.importe));
      let TB = TBon.length > 0 ? TBon.reduce((t, total) => t + total, 0) : 0;
      setTotalBon(TB);
      
      // Update final total
      let res = totalVenta - TD - TB;
      setTotalResumen(res);
    };
  return (
    <>
      <Header />
      <br />
      <br />
      {user.comisionesCreate ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card style={{ width: 'auto' }}">
              <div className="card-body">
                <Row>
                  <Col md={8}>
                    <Button
                      size="sm"
                      href="/ListadoComisiones"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Col>
                </Row>
                <br />
                <h3 align="center">Comisiones Vendedor</h3>
                {cambioVista == 0 ? (
                  <>
                    <Row>
                      <Col md={2}>
                        <Label>Vendedor</Label>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedColaborador}
                          onChange={(e) => {
                            setSelectedColaborador(e.target.value);
                          }}
                        >
                          <option value="0">Selecciona</option>
                          {colaboradores.map((a) => {
                            if (a.vendedor == "Si") {
                              return (
                                <option value={a._id}>
                                  {a.nombre} {a.apellido}
                                </option>
                              );
                            }
                          })}
                        </Input>
                      </Col>
                      <Col md={3}>
                        <Label>Cliente</Label>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedCliente}
                          onChange={(e) => {
                            setSelectedCliente(e.target.value);
                          }}
                        >
                          <option value="">Selecciona</option>
                          {clientes
                            .sort((a, b) =>
                              a.nombre_comercial > b.nombre_comercial ? 1 : -1
                            )
                            .map((a) => {
                              return (
                                <option value={a._id}>
                                  {a.nombre_comercial}
                                </option>
                              );
                            })}
                        </Input>
                      </Col>

                      <Col md={7}>
                        <ButtonGroup id="logoutBoton">
                          {cambioVista > 0 ? (
                            <Button
                              color="info"
                              size="sm"
                              className="btn btn-success"
                              onClick={(e) => {
                                setCambioVista(cambioVista - 1);
                                let res = totalVenta - totalDev - totalBon;
                                setTotalResumen(res);
                              }}
                            >
                              Regresar
                            </Button>
                          ) : (
                            <Button
                              color="info"
                              size="sm"
                              className="btn btn-success"
                              disabled
                            >
                              Regresar
                            </Button>
                          )}
                          {selectedColaborador ? (
                            <Button
                              color="danger"
                              size="sm"
                              className="btn btn-success"
                              onClick={(e) => {
                                // If we're moving from the sales view to devolutions view
                                if (cambioVista === 0) {
                                  // Filter related transactions based on selected sales
                                  filterRelatedTransactions();
                                }
                                setCambioVista(cambioVista + 1);
                                let res = totalVenta - totalDev - totalBon;
                                setTotalResumen(res);
                              }}
                            >
                              Continuar
                            </Button>
                          ) : (
                            <Button
                              color="danger"
                              size="sm"
                              className="btn btn-success"
                              disabled
                            >
                              Continuar
                            </Button>
                          )}

                          {validaBoton && cambioVista == 3 ? (
                            <Button
                              color="success"
                              className="btn btn-success"
                              size="sm"
                              onClick={savePago}
                            >
                              Guardar
                            </Button>
                          ) : (
                            <Button
                              color="success"
                              type="submit"
                              className="btn btn-success"
                              size="sm"
                              disabled
                            >
                              Guardar
                            </Button>
                          )}
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <br />
                    <h4>Ventas</h4>
                    {inputFieldsVentas.length>0?(
                      <>
                      <Button onClick={handleSelectAllVentas}>
                        {selectAllVentas
                          ? "Deseleccionar Todo"
                          : "Seleccionar Todo"}
                      </Button>
                    <Table striped borderless>
                    <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Nota</th>
                        <th>Cliente</th>
                        <th>Importe</th>
                        <th>Pagar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inputFieldsVentas.map((a) => (
                        <tr>
                          <td>{a.fecha}</td>
                          <td>{a.numero}</td>
                          <td>{a.clientes}</td>
                          <td>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(a.importe)}
                          </td>
                          <td>
                            <Input
                              type="checkbox"
                              onChange={(e) => {
                                let checked = e.target.checked;
                                if (checked == true) {
                                  setInputFieldsVentas(
                                    inputFieldsVentas.map((data) => {
                                      if (a._id == data._id) {
                                        data.select = checked;
                                        data.comision =
                                          (parseFloat(data.importe) *
                                            porcentaje) /
                                          100;
                                      }
                                      return data;
                                    })
                                  );
                                } else {
                                  setInputFieldsVentas(
                                    inputFieldsVentas.map((data) => {
                                      if (a._id == data._id) {
                                        data.select = checked;
                                        data.comision = 0;
                                      }
                                      return data;
                                    })
                                  );
                                }
                                ActualizaTotalesSurtidos();
                              }}
                              checked={a.select}
                            />
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <td className="negrita" align="center">
                          Total
                        </td>
                        <td className="negrita">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 2,
                          }).format(totalVenta)}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                    <br />
                  </Table>
                  </>
                    ):( 
                      <h4>No se encontraron Movimientos....</h4>
                      )}

                  </>
                ) : cambioVista == 1 ? (
                  <>
                    <Row>
                      <Col md={2}>
                        <Label>Vendedor</Label>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedColaborador}
                          disabled
                        >
                          <option value="0">Selecciona</option>
                          {colaboradores.map((a) => {
                            if (a.vendedor == "Si") {
                              return (
                                <option value={a._id}>
                                  {a.nombre} {a.apellido}
                                </option>
                              );
                            }
                          })}
                        </Input>
                      </Col>
                      <Col md={3}>
                        <Label>Cliente</Label>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedCliente}
                          disabled
                        >
                          <option value="">Selecciona</option>
                          {clientes
                            .sort((a, b) =>
                              a.nombre_comercial > b.nombre_comercial ? 1 : -1
                            )
                            .map((a) => {
                              return (
                                <option value={a._id}>
                                  {a.nombre_comercial}
                                </option>
                              );
                            })}
                        </Input>
                      </Col>

                      <Col md={7}>
                        <ButtonGroup id="logoutBoton">
                          {cambioVista > 0 ? (
                            <Button
                              color="info"
                              size="sm"
                              className="btn btn-success"
                              onClick={(e) => {
                                setCambioVista(cambioVista - 1);
                                let res = totalVenta - totalDev - totalBon;
                                setTotalResumen(res);
                              }}
                            >
                              Regresar
                            </Button>
                          ) : (
                            <Button
                              color="info"
                              size="sm"
                              className="btn btn-success"
                              disabled
                            >
                              Regresar
                            </Button>
                          )}
                          {selectedColaborador ? (
                            <Button
                              color="danger"
                              size="sm"
                              className="btn btn-success"
                              onClick={(e) => {
                                // If we're moving from the sales view to devolutions view
                                if (cambioVista === 0) {
                                  // Filter related transactions based on selected sales
                                  filterRelatedTransactions();
                                }
                                setCambioVista(cambioVista + 1);
                                let res = totalVenta - totalDev - totalBon;
                                setTotalResumen(res);
                              }}
                            >
                              Continuar
                            </Button>
                          ) : (
                            <Button
                              color="danger"
                              size="sm"
                              className="btn btn-success"
                              disabled
                            >
                              Continuar
                            </Button>
                          )}

                          {validaBoton && cambioVista == 3 ? (
                            <Button
                              color="success"
                              className="btn btn-success"
                              size="sm"
                              onClick={savePago}
                            >
                              Guardar
                            </Button>
                          ) : (
                            <Button
                              color="success"
                              type="submit"
                              className="btn btn-success"
                              size="sm"
                              disabled
                            >
                              Guardar
                            </Button>
                          )}
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <br />
                    <h4>Devoluciones</h4>
                    {inputFieldsDevs.length>0?(
                      <>
                      <Button onClick={handleSelectAllDevs}>
                        {selectAllDevs
                          ? "Deseleccionar Todo"
                          : "Seleccionar Todo"}
                      </Button>
                    <Table striped borderless>
                    <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Nota</th>
                        <th>Cliente</th>
                        <th>Importe</th>
                        <th>Pagar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inputFieldsDevs.map((a) => (
                        <tr>
                          <td>{a.fecha}</td>
                          <td>{a.numero}</td>
                          <td>{a.clientes}</td>
                          <td>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(a.importe)}
                          </td>
                          <td>
                            <Input
                              type="checkbox"
                              onChange={(e) => {
                                let checked = e.target.checked;
                                if (checked == true) {
                                  setInputFieldsDevs(
                                    inputFieldsDevs.map((data) => {
                                      if (a._id == data._id) {
                                        data.select = checked;
                                        data.comision =
                                          (parseFloat(data.importe) *
                                            porcentaje) /
                                          100;
                                      }
                                      return data;
                                    })
                                  );
                                } else {
                                  setInputFieldsDevs(
                                    inputFieldsDevs.map((data) => {
                                      if (a._id == data._id) {
                                        data.select = checked;
                                        data.comision = 0;
                                      }
                                      return data;
                                    })
                                  );
                                }
                                ActualizaTotalesDevs();
                              }}
                              checked={a.select}
                            />
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <td className="negrita" align="center">
                          Total
                        </td>
                        <td className="negrita">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 2,
                          }).format(totalDev)}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                    <br />
                  </Table>
                  </>
                    ):( 
                      <h4>No se encontraron Movimientos....</h4>
                      )}

                  </>
                ) : cambioVista == 2 ? (
                  <>
                    <Row>
                      <Col md={2}>
                        <Label>Vendedor</Label>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedColaborador}
                          disabled
                        >
                          <option value="0">Selecciona</option>
                          {colaboradores.map((a) => {
                            if (a.vendedor == "Si") {
                              return (
                                <option value={a._id}>
                                  {a.nombre} {a.apellido}
                                </option>
                              );
                            }
                          })}
                        </Input>
                      </Col>
                      <Col md={3}>
                        <Label>Cliente</Label>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedCliente}
                          disabled
                        >
                          <option value="">Selecciona</option>
                          {clientes
                            .sort((a, b) =>
                              a.nombre_comercial > b.nombre_comercial ? 1 : -1
                            )
                            .map((a) => {
                              return (
                                <option value={a._id}>
                                  {a.nombre_comercial}
                                </option>
                              );
                            })}
                        </Input>
                      </Col>

                      <Col md={7}>
                        <ButtonGroup id="logoutBoton">
                          {cambioVista > 0 ? (
                            <Button
                              color="info"
                              size="sm"
                              className="btn btn-success"
                              onClick={(e) => {
                                setCambioVista(cambioVista - 1);
                                let res = totalVenta - totalDev - totalBon;
                                setTotalResumen(res);
                              }}
                            >
                              Regresar
                            </Button>
                          ) : (
                            <Button
                              color="info"
                              size="sm"
                              className="btn btn-success"
                              disabled
                            >
                              Regresar
                            </Button>
                          )}
                          {selectedColaborador ? (
                            <Button
                              color="danger"
                              size="sm"
                              className="btn btn-success"
                              onClick={(e) => {
                                // If we're moving from the sales view to devolutions view
                                if (cambioVista === 0) {
                                  // Filter related transactions based on selected sales
                                  filterRelatedTransactions();
                                }
                                setCambioVista(cambioVista + 1);
                                let res = totalVenta - totalDev - totalBon;
                                setTotalResumen(res);
                              }}
                            >
                              Continuar
                            </Button>
                          ) : (
                            <Button
                              color="danger"
                              size="sm"
                              className="btn btn-success"
                              disabled
                            >
                              Continuar
                            </Button>
                          )}

                          {validaBoton && cambioVista == 3 ? (
                            <Button
                              color="success"
                              className="btn btn-success"
                              size="sm"
                              onClick={savePago}
                            >
                              Guardar
                            </Button>
                          ) : (
                            <Button
                              color="success"
                              type="submit"
                              className="btn btn-success"
                              size="sm"
                              disabled
                            >
                              Guardar
                            </Button>
                          )}
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <br />
                    <h4>Bonificaciones</h4>
                    {inputFieldsBons.length>0?(
                       <>
                        <Button onClick={handleSelectAllBons}>
                          {selectAllBons
                            ? "Deseleccionar Todo"
                            : "Seleccionar Todo"}
                        </Button>
                    <Table striped borderless>
                    <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Nota</th>
                        <th>Cliente</th>
                        <th>Importe</th>
                        <th>Pagar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inputFieldsBons.map((a) => (
                        <tr>
                          <td>{a.fecha}</td>
                          <td>{a.numero}</td>
                          <td>{a.clientes}</td>
                          <td>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(a.importe)}
                          </td>
                          <td>
                            <Input
                              type="checkbox"
                              onChange={(e) => {
                                let checked = e.target.checked;
                                if (checked == true) {
                                  setInputFieldsBons(
                                    inputFieldsBons.map((data) => {
                                      if (a._id == data._id) {
                                        data.select = checked;
                                        data.comision =
                                          (parseFloat(data.importe) *
                                            porcentaje) /
                                          100;
                                      }
                                      return data;
                                    })
                                  );
                                } else {
                                  setInputFieldsBons(
                                    inputFieldsBons.map((data) => {
                                      if (a._id == data._id) {
                                        data.select = checked;
                                        data.comision = 0;
                                      }
                                      return data;
                                    })
                                  );
                                }
                                ActualizaTotalesBons();
                              }}
                              checked={a.select}
                            />
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <td className="negrita" align="center">
                          Total
                        </td>
                        <td className="negrita">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 2,
                          }).format(totalBon)}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                    <br />
                  </Table>
                    </>
                    ):( 
                      <h4>No se encontraron Movimientos....</h4>
                      )}

                  </>
                ) : (
                  <>
                    <Row>
                      <Col md={2}>
                        <Label>Vendedor</Label>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedColaborador}
                          disabled
                        >
                          <option value="0">Selecciona</option>
                          {colaboradores.map((a) => {
                            if (a.vendedor == "Si") {
                              return (
                                <option value={a._id}>
                                  {a.nombre} {a.apellido}
                                </option>
                              );
                            }
                          })}
                        </Input>
                      </Col>
                      <Col md={3}>
                        <Label>Cliente</Label>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={selectedCliente}
                          disabled
                        >
                          <option value="">Selecciona</option>
                          {clientes
                            .sort((a, b) =>
                              a.nombre_comercial > b.nombre_comercial ? 1 : -1
                            )
                            .map((a) => {
                              return (
                                <option value={a._id}>
                                  {a.nombre_comercial}
                                </option>
                              );
                            })}
                        </Input>
                      </Col>

                      <Col md={7}>
                        <ButtonGroup id="logoutBoton">
                          {cambioVista > 0 ? (
                            <Button
                              color="info"
                              size="sm"
                              className="btn btn-success"
                              onClick={(e) => {
                                setCambioVista(cambioVista - 1);
                                let res = totalVenta - totalDev - totalBon;
                                setTotalResumen(res);
                              }}
                            >
                              Regresar
                            </Button>
                          ) : (
                            <Button
                              color="info"
                              size="sm"
                              className="btn btn-success"
                              disabled
                            >
                              Regresar
                            </Button>
                          )}

                          <Button
                            color="danger"
                            size="sm"
                            className="btn btn-success"
                            disabled
                          >
                            Continuar
                          </Button>

                          {validaBoton && cambioVista == 3 ? (
                            <Button
                              color="success"
                              className="btn btn-success"
                              size="sm"
                              onClick={savePago}
                            >
                              Guardar
                            </Button>
                          ) : (
                            <Button
                              color="success"
                              type="submit"
                              className="btn btn-success"
                              size="sm"
                              disabled
                            >
                              Guardar
                            </Button>
                          )}
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <br />
                    <div className="card container col-sm-5">
                      <h4 style={{ "font-weight": "bold" }}>Resumen</h4>
                      <Table>
                        <tr>
                          <th>Ventas</th>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(totalVenta)}
                          </td>
                        </tr>

                        <tr>
                          <th>Devoluciones</th>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(totalDev)}
                          </td>
                        </tr>

                        <tr>
                          <th>Bonificaciones</th>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(totalBon)}
                          </td>
                        </tr>

                        <tr>
                          <th>Total</th>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(totalResumen)}
                          </td>
                        </tr>

                        <tr>
                          <th>Porcentaje</th>
                          <td align="right">
                            <Row>
                              <Col md={9}></Col>
                              <Col md={3}>
                                <Input
                                  bsSize="sm"
                                  type="number"
                                  min="0"
                                  step="any"
                                  value={porcentaje}
                                  onChange={(e) => {
                                    calculaComision(e.target.value);
                                  }}
                                />
                              </Col>
                            </Row>
                          </td>
                        </tr>

                        <tr>
                          <th>Comision a Pagar</th>
                          <td style={{ "font-weight": "bold" }} align="right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(total)}
                          </td>
                        </tr>
                      </Table>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ComisionesCreate;
