import React, { useContext, useState, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Row, Input, Label, Col, Button, Badge, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import QRCode from "react-qr-code";
import Header from "../../layout/Header/Header";
import Baja from "../Baja";
function WhatsApp() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const URL_TELEFONOS_WHATSAPP = process.env.REACT_APP_URL_TELEFONOS_WHATSAPP;
  const URL_BANCOS_WHATSAPP = process.env.REACT_APP_URL_BANCOS_WHATSAPP;
  const URL_LINKS_WHATSAPP = process.env.REACT_APP_URL_LINKS_WHATSAPP;
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [qrData, setQRData] = useState("");
  const [status, setStatus] = useState("Esperando Estado...");
  const [telefonos, setTelefonos] = useState([]);
  const [links, setLinks] = useState([]);

  
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [idEdit, setIdEdit] = useState("")
  const [nameEdit, setNameEdit] = useState("")
  const [telefonoEdit, setTelefonoEdit] = useState()
  const [tipoEdit, setTipoEdit] = useState("")

  const [modalAgregar, setModalAgregar] = useState(false);
  const toggleAgregar = () => setModalAgregar(!modalAgregar);

  const [nameAgregar, setNameAgregar] = useState("")
  const [telefonoAgregar, setTelefonoAgregar] = useState()
  const [tipoAgregar, setTipoAgregar] = useState("Admin")

  const [bancos, setBancos] = useState([]);
  const [modalAgregarBancos, setModalAgregarBancos] = useState(false);
  const toggleAgregarBancos = () => setModalAgregarBancos(!modalAgregarBancos);
  const [modalEditBancos, setModalEditBancos] = useState(false);
  const toggleEditBancos = () => setModalEditBancos(!modalEditBancos);
  const [idEditBancos, setIdEditBancos] = useState("");
  const [nameEditBancos, setNameEditBancos] = useState("");
  const [bancoEdit, setBancoEdit] = useState("");
  const [tipoBancoEdit, setTipoBancoEdit] = useState("")
  const [cuentaEdit, setCuentaEdit] = useState("");
  const [fiscalEdit, setFiscalEdit] = useState("");
  const [nameAgregarBancos, setNameAgregarBancos] = useState("");
  const [bancoAgregar, setBancoAgregar] = useState("");
  const [cuentaAgregar, setCuentaAgregar] = useState(0);
  const [tipoBancoAgregar, setTipoBancoAgregar] = useState("")
  const [fiscalAgregar, setFiscalAgregar] = useState("")

  const [modalAgregarLinks, setModalAgregarLinks] = useState(false);
  const toggleAgregarLinks = () => setModalAgregarLinks(!modalAgregarLinks);
  const [modalEditLinks, setModalEditLinks] = useState(false);
  const toggleEditLinks = () => setModalEditLinks(!modalEditLinks);
  const [idEditLinks, setIdEditLinks] = useState("");
  const [nameEditLinks, setNameEditLinks] = useState("");
  const [linkEdit, setLinkEdit] = useState("");
  const [nameAgregarLinks, setNameAgregarLinks] = useState("");
  const [linkAgregar, setLinkAgregar] = useState();

  const [razonSocialEdit, setRazonSocialEdit] = useState("");
  const [claveEdit, setClaveEdit] = useState("");
  const [tarjetaEdit, setTarjetaEdit] = useState(0);
  const [montoMaximoEdit, setMontoMaximoEdit] = useState(0);
  const [razonSocialAgregar, setRazonSocialAgregar] = useState("");
  const [claveAgregar, setClaveAgregar] = useState("");
  const [tarjetaAgregar, setTarjetaAgregar] = useState(0);
  const [montoMaximoAgregar, setMontoMaximoAgregar] = useState(0);

  useMemo(() => {
    axios
    .get(`${URL_TELEFONOS_WHATSAPP}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allTelefonos = response.data;
      console.log(allTelefonos);
      setTelefonos(allTelefonos);
    })
    .catch((err) => {
      console.log(err);
    });

    axios
    .get(`${URL_BANCOS_WHATSAPP}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allBancos = response.data;
      setBancos(allBancos);
    })
    .catch((err) => {
      console.log(err);
    });

    axios
    .get(`${URL_LINKS_WHATSAPP}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allLinks = response.data;
      setLinks(allLinks);
    })
    .catch((err) => {
      console.log(err);
    });

  },[])

  setInterval(async () => {
    axios
      .get(`${URL_WHATSAPP}Status`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allStatus = response.data.state;
        setStatus(allStatus);
      })
      .catch((err) => {
        console.log(err);
      });
    }, 10000);

  const login = async (update) => {
    let qrCodeInterval;
    try {
      // Function to fetch and update the QR code
      const fetchQRCode = async (update) => {
        try {
          const response = await axios.get(`${URL_WHATSAPP}Login`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          });
          
          // Close the Swal here after receiving the response
          await Swal.close();

          if (response.status == 200) {
            if (!response.data.ready) {
              setQRData(response.data.qr);
              if (!update) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            }else{
              Swal.fire({
                position: "center",
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 2000,
              })
            }
          }



          return response.data.ready;
        } catch (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 2000,
          });
          console.log(err);
        }
      };

      // Show the loading message
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Generando QR",
        showConfirmButton: false,
      });

      // Initial fetch
      await fetchQRCode(update);

      // Set up a setInterval to fetch the QR code every 30 seconds
      qrCodeInterval = setInterval(async () => {
        const result = await fetchQRCode(true);
        if (result == true) {
          setQRData("");
          clearInterval(qrCodeInterval);
        }
      }, 10000);

      // You may want to store qrCodeInterval in a state or variable to be able to clear it later if needed.
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = async () => {
    try {
      await axios
        .post(
          `${URL_WHATSAPP}SendMsg`,
          {
            number: number,
            message: message,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.message == "Enviado") {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Tu mensaje ha sido enviado",
              showConfirmButton: false,
              timer: 2000,
            });
            setNumber("")
            setMessage("")
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
    }
  };

  function editTelefono(event) {
    event.preventDefault();
    axios
      .patch(
        `${process.env.REACT_APP_URL_TELEFONOS_WHATSAPP}/${idEdit}`,
        {
          name: nameEdit,
          telefono: telefonoEdit,
          tipo: tipoEdit
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Telefono Whatsapp',
            detalle: nameEdit + " / " + telefonoEdit + " / " + tipoEdit,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function crearTelefono(event) {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_URL_TELEFONOS_WHATSAPP}`,
        {
          name: nameAgregar,
          telefono: telefonoAgregar,
          tipo: tipoAgregar
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Agregar Telefono Whatsapp',
            detalle: nameAgregar + " / " + telefonoAgregar + " / " + tipoAgregar,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data.message}`,
        });
        console.log(error.response);
      });
  }

  function editBanco(event) {
    event.preventDefault();
    axios
      .patch(
        `${process.env.REACT_APP_URL_BANCOS_WHATSAPP}/${idEditBancos}`,
        {
          name: nameEditBancos,
          banco: bancoEdit,
          cuenta: cuentaEdit,
          tipo: tipoBancoEdit,
          fiscal: fiscalEdit,
          razonSocial: razonSocialEdit,
          clave: claveEdit,
          tarjeta: tarjetaEdit,
          montoMaximo: montoMaximoEdit
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Banco Whatsapp",
            detalle: nameEditBancos + " / " + bancoEdit ,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEditBancos();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function crearBanco(event) {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_URL_BANCOS_WHATSAPP}`,
        {
          name: nameAgregarBancos,
          banco: bancoAgregar,
          cuenta: cuentaAgregar,
          tipo: tipoBancoAgregar,
          fiscal: fiscalAgregar,
          razonSocial: razonSocialAgregar,
          clave: claveAgregar,
          tarjeta: tarjetaAgregar,
          montoMaximo: montoMaximoAgregar
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Agregar Banco Whatsapp",
            detalle:
              nameAgregarBancos + " / " + bancoAgregar,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEditBancos();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data.message}`,
        });
        console.log("hola", error.response);
      });
  }

  function editLink(event) {
    event.preventDefault();
    axios
      .patch(
        `${process.env.REACT_APP_URL_LINKS_WHATSAPP}/${idEditLinks}`,
        {
          name: nameEditLinks,
          link: linkEdit,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Link Whatsapp",
            detalle: nameEditLinks + " / " + linkEdit ,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEditLinks();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function crearLink(event) {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_URL_LINKS_WHATSAPP}`,
        {
          name: nameAgregarLinks,
          link: linkAgregar,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Agregar Link Whatsapp",
            detalle:
              nameAgregarLinks + " / " + linkAgregar,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEditLinks();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data.message}`,
        });
        console.log("hola", error.response);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.usuarios_permisos ? (
        <div className="card container col-sm-11">
          <div className="row">
            <div className="col-md-12">
              <h3 align="center">WhatsApp</h3>
              <Row>
                <Col md={6}>
                {/* <Row> */}
                <Col md={6}>
                {status == 2 ? (
                <Badge
                  color="info"
                  className="BadgeSize badge-pill">
                    CONNECTED
                  </Badge>
                  
                ):
                <Badge
                  color="danger"
                  className="BadgeSize badge-pill">
                    NOT CONNECTED
                  </Badge>
            }
                  </Col>
              {/* </Row> */}
              <br />
              {/* <Row> */}
                <Col>
                <h4>Enviar Mensaje de Prueba</h4>
                </Col>
                <br />
              {/* </Row> */}
              {/* <Row> */}
                <Col md={4}>
                  <Label>Número de Teléfono:</Label>
                  <Input
                    type="number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </Col>
                <Col md={12}>
                  <Label>Mensaje:</Label>
                  <Input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Col>
              {/* </Row> */}
              <br />
              <Col md={12}>
              <Button className="btn" color="success" onClick={sendMessage}>
                Enviar Mensaje
              </Button>

              <Button
                className="btn"
                color="info"
                onClick={(e) => login(false)}
              >
                Generar QR
              </Button>
              </Col>
              <br />
              <br />

              {qrData != "" ? (
                <Col md={6}>
                  <QRCode value={qrData} />
                </Col>
              ) : (
                ""
              )}

              <Col>
            <h4>Bancos</h4>
            <Button
            size="sm"
            color="danger"
            onClick={toggleAgregarBancos}
            >
                Agregar
            </Button>
                <Table
                      striped
                      borderless
                      className="table-responsive-xl"
                    >
                      <thead>
                        <tr>
                          <th className="tituloTabla">Nombre</th>
                          <th className="tituloTabla">Banco</th>
                          <th className="tituloTabla">Cuenta</th>
                          <th className="tituloTabla">Tipo</th>
                          <th className="tituloTabla">Fiscal</th>
                          <th className="tituloTabla">Editar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bancos
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return (
                                <tr>
                                  <td>{a.name}</td>
                                  <td>{a.banco}</td>
                                  <td>{a.cuenta}</td>
                                  <td>{a.tipo}</td>
                                  <td>{a.fiscal}</td>
                                  <td>
                                    <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) => {
                                        setIdEditBancos(a._id);
                                        setNameEditBancos(a.name)
                                        setBancoEdit(a.banco)
                                        setCuentaEdit(a.cuenta)
                                        setTipoBancoEdit(a.tipo)
                                        setFiscalEdit(a.fiscal)
                                        setRazonSocialEdit(a.razonSocial)
                                        setClaveEdit(a.clave)
                                        setTarjetaEdit(a.tarjeta)
                                        setMontoMaximoEdit(a.montoMaximo)
                                        toggleEditBancos();
                                      }}
                                    >
                                      <i class="far fa-edit"></i>{" "}
                                    </Button>

                                    <Baja
                                      idStatus={a._id}
                                      is_active={a.is_active}
                                      URL_BAJA={
                                        process.env.REACT_APP_URL_BANCOS_WHATSAPP
                                      }
                                    />
                              
                                   
                                  </td>
                                </tr>
                              );
                          })}
                      </tbody>
                    </Table>
                </Col>

                </Col>
                <Col>
            <h4>Telefonos Registrados</h4>
            <Button
            size="sm"
            color="danger"
            onClick={toggleAgregar}
            >
                Agregar
            </Button>
                <Table
                      striped
                      borderless
                      className="table-responsive-xl"
                    >
                      <thead>
                        <tr>
                          <th className="tituloTabla">Nombre</th>
                          <th className="tituloTabla">Telefono</th>
                          <th className="tituloTabla">Tipo</th>
                          <th className="tituloTabla">Editar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {telefonos
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return (
                                <tr>
                                  <td>{a.name}</td>
                                  <td>{a.telefono}</td>
                                  <td>{a.tipo}</td>
                                  <td>
                                    <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) => {
                                        setIdEdit(a._id);
                                        setNameEdit(a.name)
                                        setTelefonoEdit(a.telefono)
                                        setTipoBancoEdit(a.tipo)
                                        toggleEdit();
                                      }}
                                    >
                                      <i class="far fa-edit"></i>{" "}
                                    </Button>

                                    <Baja
                                      idStatus={a._id}
                                      is_active={a.is_active}
                                      URL_BAJA={
                                        process.env.REACT_APP_URL_TELEFONOS_WHATSAPP
                                      }
                                    />
                              
                                   
                                  </td>
                                </tr>
                              );
                          })}
                      </tbody>
                    </Table>
                </Col>
              </Row>

              <Row>    
            <Col>
            <h4>Links</h4>
            <Button
            size="sm"
            color="danger"
            onClick={toggleAgregarLinks}
            >
                Agregar
            </Button>
                <Table
                      striped
                      borderless
                      className="table-responsive-xl"
                    >
                      <thead>
                        <tr>
                          <th className="tituloTabla">Nombre</th>
                          <th className="tituloTabla">Link</th>
                          <th className="tituloTabla">Editar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {links
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return (
                                <tr>
                                  <td>{a.name}</td>
                                  <td>{a.link}</td>
                                  <td>
                                    <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) => {
                                        setIdEditLinks(a._id);
                                        setNameEditLinks(a.name)
                                        setLinkEdit(a.link)
                                        toggleEditLinks();
                                      }}
                                    >
                                      <i class="far fa-edit"></i>{" "}
                                    </Button>

                                    <Baja
                                      idStatus={a._id}
                                      is_active={a.is_active}
                                      URL_BAJA={
                                        process.env.REACT_APP_URL_LINKS_WHATSAPP
                                      }
                                    />
                              
                                   
                                  </td>
                                </tr>
                              );
                          })}
                      </tbody>
                    </Table>
                </Col>
              </Row>
             

              <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>Editar Telefono</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={nameEdit}
            required
            onChange={(e) => {
              setNameEdit(e.target.value);
            }}
          />
            <Label className="mr-sm-2">Telefono</Label>
          <Input
            className="col-sm-12"
            type="number"
            value={telefonoEdit}
            required
            onChange={(e) => {
              setTelefonoEdit(e.target.value);
            }}
          />
            <Label className="mr-sm-2">Tipo</Label>
          <Input
            className="col-sm-12"
            type="select"
            value={tipoEdit}
            required
            onChange={(e) => {
              setTipoEdit(e.target.value);
            }}
          >
            <option value="Admin">Admin</option>
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="success" 
          onClick={editTelefono}
          >
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalAgregar} toggle={toggleAgregar}>
        <ModalHeader toggle={toggleAgregar}>Agregar Telefono</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={nameAgregar}
            required
            onChange={(e) => {
              setNameAgregar(e.target.value);
            }}
          />
            <Label className="mr-sm-2">Telefono</Label>
          <Input
            className="col-sm-12"
            type="number"
            value={telefonoAgregar}
            required
            onChange={(e) => {
              setTelefonoAgregar(e.target.value);
            }}
          />
            <Label className="mr-sm-2">Tipo</Label>
          <Input
            className="col-sm-12"
            type="select"
            value={tipoAgregar}
            required
            onChange={(e) => {
              setTipoAgregar(e.target.value);
            }}
          >
            <option value="Admin">Admin</option>
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="success" 
          onClick={crearTelefono}
          >
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

                    {/* Bancos */}
                    <Modal size="sm" isOpen={modalEditBancos} toggle={toggleEditBancos}>
                <ModalHeader toggle={toggleEditBancos}>Editar Banco</ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Nombre</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={nameEditBancos}
                    required
                    onChange={(e) => {
                      setNameEditBancos(e.target.value);
                    }}
                  />
                  <Label className="mr-sm-2">Razon Social</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={razonSocialEdit}
                    required
                    onChange={(e) => {
                      setRazonSocialEdit(e.target.value);
                    }}
                  />
                  <Label className="mr-sm-2">Banco</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={bancoEdit}
                    required
                    onChange={(e) => {
                      setBancoEdit(e.target.value);
                    }}
                  />
                    <Label className="mr-sm-2">Cuenta</Label>
                  <Input
                    className="col-sm-12"
                    type="number"
                    value={cuentaEdit}
                    required
                    onChange={(e) => {
                      setCuentaEdit(e.target.value);
                    }}
                  />
                    <Label className="mr-sm-2">Clave</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={claveEdit}
                    required
                    onChange={(e) => {
                      setClaveEdit(e.target.value);
                    }}
                  />
                    <Label className="mr-sm-2">Tarjeta</Label>
                  <Input
                    className="col-sm-12"
                    type="number"
                    value={tarjetaEdit}
                    required
                    onChange={(e) => {
                      setTarjetaEdit(e.target.value);
                    }}
                  />
                   <Label className="mr-sm-2">Monto Maximo</Label>
                  <Input
                    className="col-sm-12"
                    type="number"
                    value={montoMaximoEdit}
                    required
                    onChange={(e) => {
                      setMontoMaximoEdit(e.target.value);
                    }}
                  />
                  <Label className="mr-sm-2">Tipo</Label>
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={tipoBancoEdit}
                    required
                    onChange={(e) => {
                      setTipoBancoEdit(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Transfer">Transfer</option>
                    <option value="Efectivo/Transfer">Efectivo/Transfer</option>
                  </Input>
                  <Label className="mr-sm-2">Fiscal</Label>
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={fiscalEdit}
                    required
                    onChange={(e) => {
                      setFiscalEdit(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
                </ModalBody>
                <ModalFooter>
                  <Button color="success"onClick={editBanco}>
                    Guardar
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal size="sm" isOpen={modalAgregarBancos} toggle={toggleAgregarBancos}>
                <ModalHeader toggle={toggleAgregarBancos}>
                  Agregar Banco
                </ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Nombre</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={nameAgregarBancos}
                    required
                    onChange={(e) => {
                      setNameAgregarBancos(e.target.value);
                    }}
                  />
                   <Label className="mr-sm-2">Razon Social</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={razonSocialAgregar}
                    required
                    onChange={(e) => {
                      setRazonSocialAgregar(e.target.value);
                    }}
                  />
                  <Label className="mr-sm-2">Banco</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={bancoAgregar}
                    required
                    onChange={(e) => {
                      setBancoAgregar(e.target.value);
                    }}
                  />
                    <Label className="mr-sm-2">Cuenta</Label>
                  <Input
                    className="col-sm-12"
                    type="number"
                    value={cuentaAgregar}
                    required
                    onChange={(e) => {
                      setCuentaAgregar(e.target.value);
                    }}
                  />
                  <Label className="mr-sm-2">Clave</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={claveAgregar}
                    required
                    onChange={(e) => {
                      setClaveAgregar(e.target.value);
                    }}
                  /> 
                  <Label className="mr-sm-2">Tarjeta</Label>
                  <Input
                    className="col-sm-12"
                    type="number"
                    value={tarjetaAgregar}
                    required
                    onChange={(e) => {
                      setTarjetaAgregar(e.target.value);
                    }}
                  />
                   <Label className="mr-sm-2">Monto Maximo</Label>
                  <Input
                    className="col-sm-12"
                    type="number"
                    value={montoMaximoAgregar}
                    required
                    onChange={(e) => {
                      setMontoMaximoAgregar(e.target.value);
                    }}
                  />
                   <Label className="mr-sm-2">Tipo</Label>
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={tipoBancoAgregar}
                    required
                    onChange={(e) => {
                      setTipoBancoAgregar(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Transfer">Transfer</option>
                    <option value="Efectivo/Transfer">Efectivo/Transfer</option>
                  </Input>
                  <Label className="mr-sm-2">Fiscal</Label>
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={fiscalAgregar}
                    required
                    onChange={(e) => {
                      setFiscalAgregar(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
                </ModalBody>
                <ModalFooter>
                  <Button color="success"  onClick={crearBanco}>
                    Guardar
                  </Button>
                </ModalFooter>
              </Modal>

              {/* Links */}
              <Modal size="sm" isOpen={modalEditLinks} toggle={toggleEditLinks}>
                <ModalHeader toggle={toggleEditLinks}>Editar Link</ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Nombre</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={nameEditLinks}
                    required
                    onChange={(e) => {
                      setNameEditLinks(e.target.value);
                    }}
                  />
                  <Label className="mr-sm-2">Link</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={linkEdit}
                    required
                    onChange={(e) => {
                      setLinkEdit(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color="success"onClick={editLink}>
                    Guardar
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal size="sm" isOpen={modalAgregarLinks} toggle={toggleAgregarLinks}>
                <ModalHeader toggle={toggleAgregarLinks}>
                  Agregar Link
                </ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Nombre</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={nameAgregarLinks}
                    required
                    onChange={(e) => {
                      setNameAgregarLinks(e.target.value);
                    }}
                  />
                  <Label className="mr-sm-2">Link</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={linkAgregar}
                    required
                    onChange={(e) => {
                      setLinkAgregar(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color="success"  onClick={crearLink}>
                    Guardar
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default WhatsApp;
